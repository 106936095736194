/*
=========================================================
* HanKun Cloud Workflow PRO - v1.0.0
=========================================================

* Product Page:  https://workflow.hk.run
* Copyright 2022 Creative Tim (https://www.hk.run)
* Coded by www.hk.run

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import App from "./App.vue";
import router from "./router";
import hkcloud from "../hkcloud/index.js";

import element from "./plugins/element";
import "@/plugins/tailwind.css";
import "@/plugins/icon/index.css";

new hkcloud({
  element,
  router,
  render: (h) => h(App),
}).$mount("#app");
