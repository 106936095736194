import AuthCoverLayout from "@/layout/AuthCoverLayout";
import AdminCoverLayout from "@/layout/AdminCoverLayout";
import admin from "@/router/admin";

export default [
  // {
  //   path: "/",
  //   component: AuthCoverLayout,
  //   name: "homeMain",
  //   redirect: "/pages/home/home",
  //   children: [
  //     {
  //       path: "/pages/home/home",
  //       name: "SignUpCover",
  //       component: () => import("../pages/home/home.vue"),
  //     },
  //   ],
  // },
  {
    path: "/",
    component: AuthCoverLayout,
    name: "loginMain",
    redirect: "/pages/login/login",
    children: [
      {
        path: "/pages/login/login",
        name: "login",
        component: () => import("../pages/login/login.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: AdminCoverLayout,
    name: "adminLoginMain",
    redirect: "/pages/panel/panel",
    children: admin,
  },
];
