<template>
  <el-container class="h-full bg-gray-50">
    <el-aside width="220px" class="h-full bg-white">
      <div class="logo">
        <img src="@/assets/logo.png" alt="" srcset="" />
      </div>
      <el-menu :default-active="activeMenu" router>
        <template v-for="(item, index) in menuList">
          <el-menu-item :index="item.path" :to="item.path">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-container class="h-full p-0 m-0">
      <el-header class="flex justify-between bg-white">
        <div class="flex items-center">
          <el-page-header @back="goBack" :content="title"> </el-page-header>
        </div>
        <div class="user flex items-center">
          <el-button class="mr-10" type="success" @click="goWX">
            微信后台
          </el-button>
          <el-popover placement="bottom" width="120" trigger="click">
            <div class="flex flex-col justify-center items-center">
              <div>
                <el-button
                  type="text"
                  icon="el-icon-user-solid"
                  @click="() => (passwordVisible = true)"
                  >修改密码</el-button
                >
              </div>
              <div>
                <el-button
                  type="text"
                  icon="el-icon-guide"
                  @click="handleOutLogin"
                  >退出登录</el-button
                >
              </div>
            </div>
            <el-button slot="reference" type="text"
              >管理员 <i class="el-icon-caret-bottom"></i
            ></el-button>
          </el-popover>
        </div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>

      <el-dialog
        title="修改密码"
        :visible.sync="passwordVisible"
        width="30%"
        v-loading="loading"
        custom-class="edit-password"
        :before-close="() => (passwordVisible = false)"
      >
        <el-input
          type="password"
          placeholder="请输入新密码"
          v-model="password"
        ></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="passwordVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleEditPassword"
            >修 改</el-button
          >
        </span>
      </el-dialog>
    </el-container>
  </el-container>
</template>
<script>
import admin from "@/router/admin";
import Cookies from "js-cookie";
export default {
  name: "AuthCoverLayout",
  /**
   * 调用组建
   * @type {Object}
   */
  components: {},
  /**
   * 组建属性
   * @type {Object}
   */
  props: {},
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    return {
      menuList: admin,
      title: "",

      loading: false,
      password: "", // 新密码
      passwordVisible: false,
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(v) {
        let find = this.menuList.find((item) => item.path === v.path);
        if (find) {
          this.title = find.title;
        }
      },
    },
    passwordVisible(val) {
      if (val) {
        this.password = "";
      }
    },
  },
  /**
   * 页面创建时
   * @return {[type]} [description]
   */
  created() {},
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {},
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    /**
     *  @actions:  返回
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/26 0:01
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    goBack() {
      this.$router.go(-1);
    },
    goWX() {
      console.log("打开微信");
      window.open("https://mp.weixin.qq.com/")
    },
    /**
     *  @actions:   修改登录密码
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/12 23:25
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleEditPassword() {
      if (this.password) {
        if (this.password.length >= 6) {
          this.loading = true;
          this.$cloud
            .post("account/edit_pass", {
              password: this.password,
            })
            .then(() => {
              this.loading = false;
              this.$message.success("修改成功！");
              setTimeout(() => {
                Cookies.remove("hankun_cloud_template_token");
                this.$cloud.go("/pages/login/login");
              });
            })
            .catch((err) => {
              this.loading = false;
              this.$message.error(err);
            });
        } else {
          this.$message.error("密码长度不能小于6位");
        }
      } else {
        this.$message.error("请输入新密码");
      }
    },

    /**
     *  @actions:  退出登录
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/17 22:49
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleOutLogin() {
      this.$confirm("您确定退出系统吗？", "退出系统", {
        type: "warning",
      })
        .then(() => {
          console.log("退出系统！");
          Cookies.remove("hankun_cloud_template_token");
          this.$cloud.go("/pages/login/login");
        })
        .catch(() => {
          // this.$message.error(err)
        });
    },
  },
};
</script>
<style lang="scss">
.el-menu-item.is-active {
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 56px;
    width: 4px;
    background: #f78b14;
    border-radius: 4px;
  }
}
.edit-password {
  .el-dialog__body {
    border-top: solid 1px #dcdfe6 !important;
    border-bottom: solid 1px #dcdfe6 !important;
  }
}
</style>
