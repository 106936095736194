import user from "../store/user";

/**
 * 处理账号类
 */
export default class account {
  constructor() {
    user.install();
    this.state = user.getStore().state;
  }

  /**
   * 执行登录
   */
  login(url, userInfo) {
    return new Promise((resolve, reject) => {
      user
        .getStore()
        .dispatch("login", {
          url,
          userInfo,
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  userInfo(url) {
    return new Promise((resolve, reject) => {
      user
        .getStore()
        .dispatch("getInfo", { url: url })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
