import store from "./store";
import { setToken, removeToken } from "../utils/auth";

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
};

const actions = {
  /**
   * 执行用户登录
   * @param commit
   * @param userInfo
   * @returns {Promise<unknown>}
   */
  login({ commit }, data) {
    let { url, userInfo } = data;
    // const {username, password} = userInfo
    return new Promise((resolve, reject) => {
      $hankun.cloud
        .post(url, userInfo)
        .then((data) => {
          commit("SET_USERINFO", data);
          setToken(data.token);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get user info
  getInfo({ commit }, data) {
    let { url } = data;
    return new Promise((resolve, reject) => {
      $hankun.cloud
        .get(url)
        .then((data) => {
          if (!data) {
            reject("Verification failed, please Login again.");
          }
          commit("SET_USERINFO", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user logout
  logout() {
    return new Promise((resolve, reject) => {
      removeToken();
      resolve(1);
    });
  },
};

/**
 * 导出菜单
 */
export default {
  install() {
    const storeName = "user_application";
    // 判断是否注册了模型
    if (!store.hasModule(storeName)) {
      store.registerModule([storeName], {
        namespaced: true,
        state: {
          routes: [],
          userInfo: {},
        },
        mutations,
        actions,
      });
    }
  },
  uninstall() {
    const storeName = "user_application";
    // 判断要注销的模块是否存在
    if (store.hasModule(storeName)) {
      store.unregisterModule([storeName]);
    }
  },
  getStore() {
    const storeName = "user_application";
    return {
      state: store.state[storeName],
      commit(type, payload, options) {
        type = storeName + "/" + type;
        return store.commit(type, payload, options);
      },
      dispatch(type, payload) {
        type = storeName + "/" + type;
        return store.dispatch(type, payload);
      },
    };
  },
};
