import Cookies from "js-cookie";

const TokenKey = "hankun_cloud_template_token";
const RefreshKey = "hankun_cloud_refresh_token";
const LocationhKey = "hankun_cloud_refresh_token";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function setRefreshToken(token) {
  return Cookies.set(RefreshKey, token);
}

export function getRefreshToken() {
  return Cookies.get(RefreshKey);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getLocation() {
  let data = Cookies.get(LocationhKey);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }
  return null;
}

export function setLocation(data) {
  return Cookies.set(LocationhKey, JSON.stringify(data));
}

export function removeLocation() {
  return Cookies.remove(LocationhKey);
}
