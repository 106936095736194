export default [
  {
    path: "/pages/panel/panel",
    name: "panel",
    icon: "el-icon-ali-panel",
    title: "仪表盘",
    component: () => import("../pages/panel/panel.vue"),
  },
  // {
  //   path: "/pages/article/article",
  //   name: "article",
  //   icon: "el-icon-ali-j-page",
  //   title: "文章管理",
  //   component: () => import("../pages/article/article.vue"),
  // },
  // {
  //   path: "/pages/category/category",
  //   name: "category",
  //   icon: "el-icon-ali-project",
  //   title: "分类管理",
  //   component: () => import("../pages/category/category.vue"),
  // },
  {
    path: "/pages/advance/advance",
    name: "advance",
    icon: "el-icon-ali-time",
    title: "预约管理",
    component: () => import("../pages/advance/advance.vue"),
  },
  {
    path: "/pages/time/time",
    name: "advance",
    icon: "el-icon-ali-date",
    title: "时间管理",
    component: () => import("../pages/time/time.vue"),
  },
  {
    path: "/pages/account/account",
    name: "account",
    icon: "el-icon-ali-my",
    title: "用户管理",
    component: () => import("../pages/account/account.vue"),
  },
  {
    path: "/pages/setting/setting",
    name: "setting",
    icon: "el-icon-ali-set",
    title: "系统设置",
    component: () => import("../pages/setting/setting.vue"),
  },
];
