import Vue from "vue";
import { routerDefend } from "./router/defend";
import lodash from "lodash";
// 路由信息处理
import router from "./router/router";
// 加载方法
import cloud from "./cloud";
// 加载账号处理
import account from "./account";

class hkcloud {
  constructor(object) {
    const hankun = {
      account: new account(),
      cloud: new cloud()
    };
    if (window) {
      window._ = lodash;
      window.$hankun = window.$HK = hankun;
    }
    //注册全局函数
    Vue.prototype.$cloud = hankun.cloud;
    Vue.config.productionTip = false;
    routerDefend(object.router);
    return new Vue({
      router: router,
      vuetify: object.vuetify || {},
      render: object.render
    });
  }

  /**
   * 扩展VUE的use方法
   * @param object
   */
  static use(object) {
    Vue.use(object);
  }

  /**
   * 返回默认VUE的实例
   * @returns {Vue | VueConstructor}
   */
  static vue() {
    return Vue;
  }
}

export default hkcloud;
